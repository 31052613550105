import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";

@Injectable()
export class RequestResponseHandlerInterceptor implements HttpInterceptor {
  public pendingRequest: number = 0;
  public showLoading: boolean = false;
  constructor(
    private spinnerService: NgxSpinnerService,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let req = request.clone({
      url: `${request.url}`,
    });
    req = req.clone({
      setHeaders: {
        'Accept-Language': 'vi-VN,vi;q=0.9,vi;q=0.8'
      }
    });
    if (!request?.url?.includes('RefreshToken') || !request?.url?.includes('GetTotalNewNoti')) {
      this.pendingRequest++;
      this.turnOnModal();
      return next.handle(req).pipe(tap((res: any) => {
      }, (err: any) => {
      })).pipe(
        finalize(() => {
          this.turnOffModal();
        }))
    } else {
      return next.handle(req).pipe(tap((res: any) => {
      }, (err: any) => {
      }))
    }
  }
  turnOnModal() {
    this.spinnerService.show("sp3");
  }
  turnOffModal() {
    this.pendingRequest--;
    if (this.pendingRequest <= 0) {
      this.spinnerService?.hide("sp3");
    }
  }
}
