import { BhvService } from './shared/services/api/bhv/bhv.service';
import { SignalService } from './shared/signals/signals.service';
import { afterNextRender, Component, DestroyRef, signal } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { filter, take } from 'rxjs';
import { UrlService } from './shared/services/share/url.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MobileSize } from './shared/signals/signals.model';
import { CookieService } from 'ngx-cookie-service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BreadcrumbService } from 'xng-breadcrumb';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { SeoService } from './shared/services/seo/seo.service';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgxSpinnerModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'VTI_CBH_KH_FE_NEW';
  previousUrl: string = '';
  // loadingDone = signal<boolean>(false);
  jsonLdBreadcrumbs!: SafeHtml;
  constructor(
    private router: Router,
    private signalService: SignalService,
    private urlService: UrlService,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private bhvService: BhvService,
    private destroy: DestroyRef,
    private breadcrumbService: BreadcrumbService,
    private seoService: SeoService,
    private sanitizer: DomSanitizer,
    private titleService: Title
  ) {
    // this.signalService.refreshData();
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationStart))
      .subscribe((val: any) => {
        if (val.url.includes('/campaign/')) {
          this.getLinkRedirect(val?.url);
        }
      });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((val: any) => {
        this.signalService.refreshData();
        if (this.previousUrl) {
          this.urlService.setPreviousUrl(
            JSON.parse(JSON.stringify(this.previousUrl))
          );
        }
        this.previousUrl = val.url;
        if (val?.url?.includes('referralCode')) {
          this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.cookieService.set('referralCode', params?.['referralCode'], {
              path: '/',
              sameSite: 'Strict',
            });
          });
        }
      });
    this.signalService.updateTotalNewNoti();
    this.breakpointObserver
      .observe(['(max-width: 992px)'])
      .subscribe((result) => {
        MobileSize.set(result.matches);
      });
    // afterNextRender(() => {
    //   // setTimeout(() => {
    //     this.loadingDone.set(true);
    //   // }, 0);
    // });

    this.breadcrumbService.breadcrumbs$.subscribe((data) => {
      if (data.length > 0) {
        let keywords = data.map((item: any) => item.label).join(', ');
        keywords = 'BHVIET rủi ro không cần lo, ' + keywords + ', vtts, bhviet, bao hiem viet, bảo hiểm việt, Công ty TNHH Công nghệ và Dịch vụ Viễn thông Việt Tín';
        let title = data[data.length - 1].label
        // this.titleService.setTitle(this.title);
        this.seoService.setData({
          author: 'BHVIET - bảo hiểm việt',
          title: title + ' - BHVIET',
          keywords: keywords
        });
        this.jsonLdBreadcrumbs = this.buildJsonLdBreadcrumbs(data);
        // console.log(this.jsonLdBreadcrumbs);

      }
    });
  }
  getLinkRedirect(url: string) {
    let params = {
      shortLink: url.replace('/campaign/', '')
    };
    this.bhvService.getLinkRedirect(params).pipe(takeUntilDestroyed(this.destroy)).subscribe({
      next: (response: any) => {
        if (response.succeeded) {
          location.href = response.data?.redirect;
        } else {
          location.href = '';
        }
      },
      error: (err: any) => {
        location.href = '';
      }
    });
  }
  buildJsonLdBreadcrumbs(breadcrumbs: any[]): SafeHtml {
    const jsonLd = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': breadcrumbs.map((breadcrumb, index) => ({
        'type': 'ListItem',
        'position': index + 1,
        'item': {
          'id': breadcrumb.routeLink,
          'name': breadcrumb.label
        }
      }))
    };
    return this.sanitizer.bypassSecurityTrustHtml('<script type="application/ld+json">' + JSON.stringify(jsonLd) + '</script>');
  }
}
